<template>
    <div class="content_wr">
        <article v-html="displayedContent"></article>
        <div v-if="isCollapsable">
            <p class="btn-link" @click="expandContent" v-if="isCollapsed">
                <span>Показать</span>
                <svg class="arrow-down" width="13" height="6" viewBox="0 0 13 6">
                    <use href="#arrow-down-actual" xlink:href="#arrow-down-actual"></use>
                </svg>
            </p>
            <p class="btn-link" @click="collapseContent();" v-else>
                <span>Свернуть</span>
                <svg class="arrow-down rotate" width="13" height="6" viewBox="0 0 13 6">
                    <use href="#arrow-down-actual" xlink:href="#arrow-down-actual"></use>
                </svg>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'content-with-toggle-btn',
    props: {
        content: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            displayedContent: "",
            isCollapsed: false,
        };
    },
    mounted() {
        if (this.isCollapsable) {
            this.collapseContent();
        } else {
            this.displayedContent = this.content;
        }
    },
    methods: {
        collapseContent() {
            this.displayedContent = this.content.slice(0, 120) + '...';
            this.isCollapsed = !this.isCollapsed;
        },
        expandContent() {
            this.displayedContent = this.content;
            this.isCollapsed = !this.isCollapsed;
        },
    },
    computed: {
        isCollapsable() {
            return this.content.length > 120;
        }
    }
};
</script>

<style scoped>
article {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
}

article+h2 {
    margin-top: 20px;
}

article p {
    margin: 0;
}

article p+p {
    margin-top: 10px;
}

.btn-link>span {
    text-decoration: underline;
}

.btn-link .arrow-down {
    margin-left: 5px;
}

.btn-link .arrow-down.rotate {
    transform: rotate(180deg);
}
</style>