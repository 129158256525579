<template>
    <navbar-header />
    <categories-list />
    <catalog-list />
</template>

<script>
import NavbarHeader from '@/components/NavbarHeader.vue';
import CatalogList from '@/components/CatalogList.vue';
import CategoriesList from '@/components/CategoriesList.vue';

export default {
    components: {
        NavbarHeader,
        CatalogList,
        CategoriesList,
    },
};
</script>