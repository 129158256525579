<template>
    <section class="lk-header">
        <div class="wrapper">
            <div class="section-header">
                <h1>Ваш профиль</h1>
                <router-link :to="catalogLink">К покупкам</router-link>
                <span class="btn-exit" @click="exitUser()" v-if="(isLoggedIn && appMode === 2)">
                    <span>Выйти</span>
                    <svg width="20" height="16" viewBox="0 0 20 16">
                        <use href="#exit-icon" xlink:href="#exit-icon"></use>
                    </svg>
                </span>
            </div>
            <div class="selectgroup">
                <label class="selectgroup-item" @click.prevent="$router.push('/lk/profile')">
                    <input type="radio" name="lk_razdel" value="2" class="selectgroup-input"
                        :checked="($route.name === 'lk_user')" />
                    <span class="selectgroup-button">Ваши данные</span>
                </label>
                <label class="selectgroup-item" @click.prevent="$router.push('/lk/orders')">
                    <input type="radio" name="lk_razdel" value="1" class="selectgroup-input"
                        :checked="($route.name === 'lk_orders')" />
                    <span class="selectgroup-button">История заказов</span>
                </label>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            appMode: this.$store.state.$app_mode
        }
    },
    computed: {
        catalogLink() {
            return '/catalog/';
        },
        isLoggedIn: function () {
            return this.$store.getters.isAuthenticated;
        },
    },
    methods: {
        exitUser() {
            this.$store.commit('setUserId', '');
            this.$router.push('/');
        }
    },
    mounted() {
        //переключатели разделов
        if ($('.selectgroup-input:checked').length) {
            let curRazdel = Number($('.selectgroup-input:checked').val());
            $('.selected-radio').addClass('start-' + curRazdel);
        }
    }

};
</script>

<style scoped>
.btn-exit {
    display: inline-flex;
    align-items: center;
    color: #EB5757;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
}

.btn-exit>span {
    margin-right: 10px;
}

.selectgroup {
    width: 100%;
    max-width: 100%;
    display: inline-flex;
    justify-content: space-between;
    gap: 0 10px;
}

.selected-radio {
    width: 50%;
    max-width: 100%;
}

.selectgroup-item {
    width: 50%;
    max-width: 100%;
}

.selectgroup-item .selectgroup-input {
    display: none;
}

.selectgroup-button {
    height: 32px;
    line-height: 28px;
    border: 2px solid transparent;
    background-color: var(--theme-button-text-color);
    border-radius: 8px;
    width: 100%;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: var(--theme-hint-color);
    cursor: pointer;
}

.selectgroup-input:checked+.selectgroup-button {
    border-color: var(--theme-button-color);
    background-color: transparent;
    color: var(--theme-button-color);
}

.section-header {
    width: 100%;
    margin: 10px 0 15px;
}

.section-header h1 {
    font-weight: 600;
}

.section-header a,
.section-header small {
    color: var(--theme-link-color);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-transform: none;
    white-space: nowrap;
}

.lk-header .user-name {
    font-size: 16px;
    text-align: center;
}

.lk-header .user-info {
    max-width: 100%;
    width: 100%;
}
</style>