<template>
    <div class="main-categories wrapper">
        <div class="selectgroup menu">
            <router-link class="selectgroup-item marked-item" to="/catalog/?query=скидка">
                <span class="selectgroup-button" :class="{ 'active': (isCurrentRazdel(0) && $route.query?.query ==='скидка') }">% Скидки</span>
            </router-link>
            <router-link class="selectgroup-item" v-for="item in mainRazdels" :key="item.id"
                :to="`/catalog/?categories=${item.id}`">
                <span class="selectgroup-button" :class="{ 'active': isCurrentRazdel(item.id) }">{{ item.title }}</span>
            </router-link>
        </div>
        <div class="paddles">
            <button class="left-paddle paddle hidden">
                <svg class="angle-right-icon" width="7" height="12" viewBox="0 0 7 12"
                    style="transform: rotate(180deg);">
                    <use href="#angle-right" xlink:href="#angle-right"></use>
                </svg>
            </button>
            <button class="right-paddle paddle">
                <svg class="angle-right-icon" width="7" height="12" viewBox="0 0 7 12">
                    <use href="#angle-right" xlink:href="#angle-right"></use>
                </svg>
            </button>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
    name: 'categories-list',
    data() {
        return {
            mainRazdelIds: [],
            currentCatId: 0,
        }
    },
    methods: {
        isCurrentRazdel(id) {
            let isCurr = false;
            if (id === 0 && typeof this.$route.query.categories === 'undefined') {
                isCurr = true;
            } else if (typeof this.$route.query.categories !== 'undefined') {
                isCurr = Number(this.$route.query.categories) === Number(id);
            }
            return isCurr;
        }
    },
    computed: {
        ...mapState({
            mainRazdels(state) {
                let list = [];
                if (state.razdelsList) {
                    list = state.razdelsList.filter(item => this.mainRazdelIds.includes(item.id));
                    //выбранная категория - первая в списке
                    list.sort((a, b) => a.id === this.currentCatId ? -1 : b.id === this.currentCatId ? 1 : 0);
                }
                return list;
            },
        })
    },
    mounted() {
        this.currentCatId = (typeof this.$route.query.categories !== 'undefined') ? this.$route.query.categories : 0;
        this.mainRazdelIds = process.env.VUE_APP_MAIN_RAZDEL_IDS ? process.env.VUE_APP_MAIN_RAZDEL_IDS.split(',') : [];

        this.$nextTick(function () {
            let scrollDuration = 300;
            let paddleMargin = 0;
            let leftPaddle = $('.left-paddle');
            let rightPaddle = $('.right-paddle');
            let menuSize = 0;
            let menuWrapperSize = document.querySelector('.main-categories').clientWidth;
            let allItems = document.querySelectorAll('.selectgroup .selectgroup-item');
            if (allItems.length) {
                allItems.forEach((item) => {
                    menuSize += (item.clientWidth + 13);//13px gap
                });
            }

            // get how much of menu is invisible
            var menuInvisibleSize = menuSize - menuWrapperSize;

            // get how much have we scrolled to the left
            var getMenuPosition = function () {
                return $('.menu').scrollLeft();
            };

            // finally, what happens when we are actually scrolling the menu
            $('.menu').on('scroll', function () {

                // get how much of menu is invisible
                menuInvisibleSize = menuSize - menuWrapperSize;
                // get how much have we scrolled so far
                var menuPosition = getMenuPosition();

                var menuEndOffset = menuInvisibleSize - paddleMargin;

                // show & hide the paddles 
                // depending on scroll position
                if (menuPosition <= paddleMargin) {
                    leftPaddle.addClass('hidden');
                    rightPaddle.removeClass('hidden');
                } else if (menuPosition < menuEndOffset) {
                    // show both paddles in the middle
                    leftPaddle.removeClass('hidden');
                    rightPaddle.removeClass('hidden');
                } else if (menuPosition >= menuEndOffset) {
                    leftPaddle.removeClass('hidden');
                    rightPaddle.addClass('hidden');
                }

            });

            // scroll to left
            rightPaddle.on('click', function () {
                $('.menu').animate({ scrollLeft: menuInvisibleSize }, scrollDuration);
            });

            // scroll to right
            leftPaddle.on('click', function () {
                $('.menu').animate({ scrollLeft: '0' }, scrollDuration);
            });
        });

    },
}
</script>

<style scoped>
.hidden {
    display: none;
}

.selectgroup::-webkit-scrollbar {
    display: none;
}

.selectgroup {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: hidden;
    overflow-x: auto;
    display: flex;
    gap: 0 10px;
}

.selectgroup-item.marked-item .selectgroup-button {
    background-color: var(--theme-button-color);
    color: var(--theme-button-text-color);
}

.selectgroup-item input[type=radio] {
    display: none;
}

.selectgroup-item .selectgroup-button {
    cursor: pointer;
    text-align: center;
    height: 32px;
    line-height: 28px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: var(--theme-hint-color);
    white-space: nowrap;
    padding: 0 10px;
    border-radius: 8px;
    border: 2px solid transparent;
    background-color: var(--theme-button-text-color);
    transition: all var(--trans-dur) var(--trans-timing);
}

.selectgroup-item .selectgroup-button:hover {
    color: var(--theme-hint-color);
}

.selectgroup-item input[type=radio]:checked+.selectgroup-button,
.selectgroup-item .selectgroup-button.active {
    color: var(--theme-button-color);
    border-color: var(--theme-button-color);
    background-color: transparent;
}

.main-categories {
    position: relative;
}

.paddle {
    position: absolute;
    top: 15px;
    bottom: 0;
    width: 2em;
    height: 32px;
    color: var(--theme-hint-color);
    background-color: #fff;
    border-radius: 0;
    text-align: center;
    padding: 0;
}

.left-paddle {
    left: 15px;
}

.right-paddle {
    right: 15px;
}
</style>