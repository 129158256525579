//подключаем библиотеки для всего приложения
import axios from 'axios';
const BASE_URL = process.env.VUE_APP_BASE_URL + 'api/';
axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.common['Authorization'] = 'Bearer ' + process.env.VUE_APP_API_KEY;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

import select2 from 'vue3-select2-component';

import LazyLoad from 'vue3-lazyload';

//CORE Vue
import { createApp } from 'vue';
import App from '@/App.vue';
import components from '@/components/UI';
import { authTWA } from '@/functions/auth';
import store from '@/store';
import router from '@/router';

const app = createApp(App);

//глбальная регистарция axios во всех компонентах через this.$http
app.config.globalProperties.$http = axios;
app.config.globalProperties.baseURL = BASE_URL;
//Санкт-Петербург
app.config.globalProperties.cityId = process.env.VUE_APP_CITY_ID_DEFAULT;
//имя ТГ-бота 
app.config.globalProperties.tgBotName = process.env.VUE_APP_TG_BOT;


//регистрируем наши компоненты UI глобально
//чтобы не импортировать каждый раз
components.forEach(component => {
    app.component(component.name, component);
});

//регистрируем глобальные функции
/**
 * num_word – склоняем слова в зависимости от цифры: num_word(12, ['товар', 'товара', 'товаров'])
 * price_format – красиво выводим цену (разделяем пробелом + символ валюты )
 */
app.config.globalProperties.$filters = {
    num_word(value, words) {
        value = Math.abs(value) % 100;
        var num = value % 10;
        if (value > 10 && value < 20) return words[2];
        if (num > 1 && num < 5) return words[1];
        if (num == 1) return words[0];
        return words[2];
    },
    price_format(price) {
        return new Intl.NumberFormat('ru-RU', {
            style: 'currency',
            currency: 'RUB',
            minimumFractionDigits: 2,
            maximumFractionDigits: 20,
            minimumSignificantDigits: 1,
            maximumSignificantDigits: 20
        }).format(price);
    },
    number_format(number) {
        return new Intl.NumberFormat('ru-RU', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 20,
            minimumSignificantDigits: 1,
            maximumSignificantDigits: 20
        }).format(number);
    }
}
//auth global function
app.config.globalProperties = {
    ...app.config.globalProperties,
    $auth: authTWA
};

app
    .component('select2', select2)
    .use(LazyLoad)
    .use(store)
    .use(router)
    .mount('#app');