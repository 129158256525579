<template>
  <loader />
  <top-header v-once />
  <router-view :key="$route.path"></router-view>
  <catalog-filter :is-open="$store.state.isPopupOpen" />
  <my-svg />
</template>

<style>
@import '@/assets/css/style.css';
</style>

<script>
import { mapActions } from 'vuex';
import { defineAsyncComponent } from 'vue';

//шапка сайта
import TopHeader from '@/components/TopHeader.vue';

const CatalogFilter = defineAsyncComponent({
  loader: () => import('@/components/Modals/CatalogFilter.vue')
});


export default {
  components: {
    TopHeader,
    CatalogFilter
  },
  methods: {
    ...mapActions([
      'GET_RAZDELS_LIST',
    ]),
    telegramInit() {
      Telegram.WebApp.ready();
      if (typeof Telegram.WebApp.initDataUnsafe.user !== 'undefined') {
        this.$store.state.$app_mode = 1;
        Telegram.WebApp.expand();
        Telegram.WebApp.enableClosingConfirmation();
        Telegram.WebApp.MainButton.setParams({
          text: 'ОФОРМИТЬ ЗАКАЗ',
        });
      } else {
        this.$store.state.$app_mode = 2;
      }

    },
    telegramAuthState() {
      if (this.$store.state.$app_mode === 1) {
        this.$store.commit('setUserId', '');
        this.$auth(Telegram.WebApp.initDataUnsafe.user);
      } else {
        //авторизация через браузер
      }
    },
    goToPageFromSearch(id) {
      if (id > 0) {
        this.$router.push({ name: 'product', params: { productAlias: id } });
        window.searchbooster.api.close();
      }
    }
  },
  mounted() {
    this.GET_RAZDELS_LIST();
    this.telegramInit();
    this.telegramAuthState();
    window.goToPageFromSearch = this.goToPageFromSearch;
  },
  updated() {
    if (this.$store.state.$app_mode === 1) {
      let curPage = this.$route.name;
      if (curPage === 'cart') {
        let cart = this.$store.state.cart;
        if (cart.length > 0) {
          Telegram.WebApp.MainButton.show();
        }
      } else {
        Telegram.WebApp.MainButton.hide();
      }
    }
  }
}
</script>
