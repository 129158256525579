<template>
    <p class="spin-loader__wr w-100 text-center">
        <span class="spin-loader" aria-hidden="true"></span>
    </p>
</template>

<script>
export default {
    name: 'loading'
}
</script>

<style scoped>
.spin-loader__wr{
    margin: 0;
}
.spin-loader {
    border-radius: 80%;
    display: inline-block;
    height: 30px;
    width: 30px;
    position: relative;
    -webkit-animation: spin 0.675s linear 0s infinite normal;
    animation: spin 0.675s linear 0s infinite normal;
    background: var(--theme-button-color);
}

.spin-loader:before,
.spin-loader:after {
    content: "";
    display: block;
    position: absolute;
}

.spin-loader:before {
    border-radius: 0 90px 90px 0;
    height: 30px;
    width: 50%;
    top: 0;
    right: 0;
    z-index: 1;
    background: #45750e;
    background-image: linear-gradient(var(--theme-button-color), #45750e);
}

.spin-loader:after {
    border-radius: 80%;
    height: 25px;
    width: 25px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    background: #fff;
}

@-webkit-keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
</style>