import axios from "axios";

const actions = {
    async GET_RAZDELS_LIST({ commit }) {
        try {
            const response = await axios.get('categories');
            if (response.data) {
                commit('SET_RAZDELS_LIST', response.data);
            }
        } catch (e) {
            console.log(e);
        }
    }
};

export default actions;