<template>
    <div class="top-header">
        <div class="wrapper">
            <router-link to="/" class="logo">
                <img src="@/assets/img/logo.svg" :alt="companyName" />
                <span>{{ companyName }}</span>
            </router-link>
            <div class="autocomplete">
                <span id="search-popup">Поиск</span>
            </div>
            <span class="filter-trigger" @click="open">
                <span class="choosen-flt" v-show="(isSetFilters)"></span>
                <svg class="filter-icon" width="16" height="16" viewBox="0 0 16 16">
                    <use href="#filter-icon" xlink:href="#filter-icon"></use>
                </svg>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'top-header',
    data() {
        return {
            search: '',
            companyName: process.env.VUE_APP_NAME,
        }
    },
    methods: {
        open() {
            this.$store.commit('updateStateParam', { title: 'isPopupOpen', value: true });
        },
    },
    computed: {
        isSetFilters() {
            return this.$store.getters.isSetFilters;
        }
    }
}
</script>

<style>
.top-header>.wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0;
}

.top-header .logo>img {
    width: 32px;
    height: 32px;
    display: block;
    object-fit: contain;
}

.top-header .logo>span {
    display: none;
}


.top-header .back-btn,
.top-header .filter-trigger {
    width: 32px;
    height: 32px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #F8FAFC;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    color: var(--theme-button-color);
}

.top-header .filter-trigger {
    position: relative;
}

.top-header .filter-trigger .choosen-flt {
    width: 8px;
    height: 8px;
    color: var(--theme-button-text-color);
    background: var(--theme-button-color);
    border-radius: 100%;
    position: absolute;
    top: 0px;
    right: 0px;
}

.top-header .back-btn.active>svg {
    transform: rotate(90deg);
}

.top-header .autocomplete {
    width: calc(100% - 80px);
    height: 0;
    position: relative;
    margin: 21px 0 0;
}

.autocomplete .search-icon {
    position: absolute;
    top: -13px;
    right: 10px;
    width: 16px;
    height: 16px;
}

.autocomplete .select2-container {
    height: 32px;
}

.autocomplete .select2 .selection {
    width: 100%;
}

.autocomplete .select2-container--default .select2-selection--single {
    width: 100%;
    background-color: var(--theme-bg-color);
    border: 0.5px solid var(--theme-secondary-bg-color);
    border-radius: 8px;
    height: 32px;
}

.autocomplete .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 32px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: var(--theme-hint-color);
    padding-left: 10px;
    padding-right: 30px;
}

.autocomplete .select2-container--default .select2-selection--single .select2-selection__arrow {
    display: none;
}

.select2-results__options li:last-child {
    border-radius: 0 0 8px 8px;
}

.select2-container {
    margin-top: -31.5px;
}

.select2-container--open .select2-dropdown--below {
    border-radius: 8px !important;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: var(--theme-button-color);
    color: var(--theme-button-text-color);
}

.select2-results__option {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: var(--theme-hint-color);
}

.select2-dropdown {
    max-width: 210px;
    background-color: var(--theme-bg-color);
    border: 0.5px solid var(--theme-secondary-bg-color);
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border: none;
    border-bottom: 0.5px solid var(--theme-secondary-bg-color);
    background-color: var(--theme-bg-color);
    color: var(--theme-text-color);
    border-radius: 0;
}

.autocomplete #search-popup {
    display: block;
    cursor: pointer;
    width: 100%;
    height: 32px;
    background-color: var(--theme-bg-color);
    border: .5px solid var(--theme-secondary-bg-color);
    border-radius: 8px;
    line-height: 32px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: var(--theme-hint-color);
    padding-left: 10px;
    padding-right: 30px;
    margin-top: -21.5px;
}
</style>