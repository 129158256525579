import axios from "axios";

/**
 * авторизация / регистрация пользователя
 * –после открытия приложения
 * –после авторизации через Telegram Widget
 * @param {*} userData 
 */
export async function authTWA(userData) {

    let res = {};

    if (userData) {
        try {
            const response = await axios.post('auth-user', {
                params: userData,
            });
            if (response.data) {
                this.$store.commit('setUserId', response.data.tg_username);
                res = response.data.user_info;
            }
        } catch (e) {
            console.log('authErr', e);
        }
    }

    return res;
}