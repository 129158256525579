<template>
    <navbar-header />
    <section class="success-order">
        <div class="wrapper">
            <div class="centered-block">
                <svg class="success-icon" width="102" height="102" viewBox="0 0 102 102">
                    <use href="#success-icon" xlink:href="#success-icon"></use>
                </svg>
                <p class="lead">
                    Заказ №{{ orderId }} успешно создан!
                </p>
                <p>
                    Уведомления по статусу Вашего заказа
                    будут приходить на Ваш Telegram-аккаунт
                </p>
                <router-link class="default-btn mt-2" to="/lk/orders">Перейти к заказам</router-link>
            </div>
        </div>
    </section>
</template>

<script>
//навигация по приложению
import NavbarHeader from '@/components/NavbarHeader.vue';

export default {
    name: 'success-order',
    data() {
        return {
            orderId: this.$route.query.order_id,
        }
    },
    components: {
        NavbarHeader
    },
    mounted() {
        if (typeof this.orderId === 'undefined') {
            //this.$router.push('/catalog');
        }
    }
}
</script>

<style scoped>
.success-order>.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 120px);
}

.centered-block {
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
    text-align: center;
}
</style>