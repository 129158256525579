import Loader from '@/components/UI/Loader.vue';
import Loading from '@/components/UI/Loading.vue';
import MySvg from '@/components/UI/MySvg.vue';
import ImgLazyLoad from '@/components/UI/ImgLazyLoad.vue'

export default [
    Loader,
    Loading,
    MySvg,
    ImgLazyLoad
]