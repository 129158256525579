<template>
    <navbar-header />
    <section class="product-main">
        <div class="group-btns">
            <span class="back-btn" @click="goBackLink">
                <svg class="filter-icon" width="12" height="12" viewBox="0 0 12 12">
                    <use href="#close-red" xlink:href="#close-red"></use>
                </svg>
            </span>
            <span class="share-btn" v-if="product.url" @click="shareLink()">
                <svg class="filter-icon" width="16" height="10" viewBox="0 0 16 10">
                    <use href="#share-grey" xlink:href="#share-grey"></use>
                </svg>
            </span>
        </div>
        <div class="swiper prod-images">
            <div class="swiper-wrapper" id="prod-images">
                <figure :href="galleryImg" class="swiper-slide">
                    <img-lazy-load :img-url="galleryImg" :img-title="product.name" :not-found="imgNotFound" />
                </figure>
            </div>
            <div class="swiper-pagination"></div>
        </div>
        <div class="wrapper">
            <section class="prod-info">
                <h1>{{ product.name }}</h1>
            </section>
            <div class="product-cart js-item" :class="{ selected: productTotal > 0 }" v-if="product.id">
                <div class="item-buttons__wr" v-if="(isAvailable && !onlyOffline)">
                    <div class="product-price">
                        <ins>{{ priceFormat(productPrice) }}</ins>
                        <del v-if="productOldPrice">{{ priceFormat(productOldPrice) }}</del>
                    </div>
                    <div class="item-buttons">
                        <button class="item-decr-button button-item ripple-handler" @click="removeFromCart(product.id)">
                            <span class="ripple-mask"><span class="ripple"></span></span>
                        </button>
                        <span class="counter">{{ productTotal }}</span>
                        <button class="item-incr-button button-item ripple-handler" @click="addToCart(product.id)">
                            <span class="button-item-label">В корзину</span>
                            <svg width="16" height="20" viewBox="0 0 16 20" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M1.00035 7.3C0.987 6.31988 1.77024 5.51426 2.75035 5.5H13.2503C14.2305 5.51426 15.0137 6.31988 15.0004 7.3V15.4C15.0276 17.3605 13.4608 18.972 11.5004 19H4.50035C2.53989 18.972 0.973096 17.3605 1.00035 15.4V7.3Z"
                                    stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                <path
                                    d="M11.5004 8.2V4.6C11.5276 2.63953 9.96081 1.02797 8.00035 1C6.03989 1.02797 4.4731 2.63953 4.50035 4.6V8.2"
                                    stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                        </button>
                    </div>
                    <router-link class="cart-link" to="/cart">
                        <svg width="32" height="34" viewBox="0 0 32 34">
                            <use href="#cart-added" xlink:href="#cart-added"></use>
                        </svg>
                    </router-link>
                </div>
                <div class="item-buttons__wr not-available" v-else-if="(isAvailable && onlyOffline)">
                    <span class="status">отпускается в аптеке по рецепту</span>
                </div>
                <div class="item-buttons__wr not-available" v-else-if="!isAvailable">
                    <span class="status">нет в наличии</span>
                </div>
            </div>
        </div>
        <section class="prod-about prod-about-descr wrapper" v-if="product.description">
            <h2>Описание</h2>
            <content-with-toggle-btn :content="product.description" />
        </section>
        <section class="prod-about prod-about-table wrapper" v-if="productParams.length > 0">
            <h2>Характеристики</h2>
            <table class="table">
                <tbody>
                    <tr v-for="(param, index) in productParams" :key="index">
                        <td>{{ param.name }}</td>
                        <td width="160">{{ param.value }}</td>
                    </tr>
                </tbody>
            </table>
        </section>
    </section>
    <product-caroucel :title="relatedTitle" :products="relatedProducts" :flt-params="{ categories: productCategory }" />
</template>

<script>
import { mapGetters } from 'vuex';
//навигация по приложению
import NavbarHeader from '@/components/NavbarHeader.vue';
import ContentWithToggleBtn from '@/components/ContentWithToggleBtn.vue';
//Swiper-Slider
import Swiper from '@/assets/lib/swiper/swiper-bundle.min.js';
//LightGallery
import lightGallery from '@/assets/lib/lightgallery/lightgallery.min.js';
//карусель товаров
import ProductCaroucel from '@/components/ProductCaroucel.vue';

export default {
    components: {
        NavbarHeader,
        ContentWithToggleBtn,
        ProductCaroucel
    },
    data() {
        return {
            alias: this.$route.params.productAlias,
            product: [],
            productPrice: 0,
            productOldPrice: 0,
            productCategory: 0,
            productParams: [],
            galleryImg: '',
            relatedProducts: [],
            relatedTitle: "Похожие товары",
            productIsLoad: false,
            errorImg: require('@/assets/img/loading-error.png'),
            imgNotFound: false
        }
    },
    methods: {
        async getInfo() {
            try {
                const response = await this.$http.post('find-by-id', {
                    params: {
                        id: this.alias,
                        regionId: this.cityId
                    }
                });

                if (response.data.offer) {
                    this.product = response.data.offer;

                    this.productPrice = this.product.price;
                    this.productOldPrice = this.product.oldPrice;

                    if (typeof this.product.pictures !== 'undefined') {
                        this.galleryImg = this.product.pictures[0];
                    } else {
                        this.galleryImg = this.errorImg;
                        this.imgNotFound = true;
                    }

                    if (typeof this.product.params !== 'undefined') {
                        let paramsList = [];
                        let exceptParams = ['addedDate', 'popularity', 'Цена снижена'];
                        this.product.params.find(function (param) {
                            let paramValues = Object.values(param);
                            paramValues.some(function (item) {
                                if(!exceptParams.includes(item.name)){
                                    paramsList.push(item);
                                }
                            });
                        });
                        if(paramsList.length){
                            this.productParams  = paramsList;
                        }
                    }

                    if (typeof this.product.vendor !== 'undefined') {
                        this.productParams.push({ 'name': 'Бренд', 'value': this.product.vendor });
                    }

                    this.productCategory = this.product.categories[0]['id'];
                    if (this.product.categories) {
                        let categoriesStr = '';
                        this.product.categories.forEach((item) => {
                            categoriesStr += `${item.name}<br/>`;
                        });
                        categoriesStr = categoriesStr.replace(/<br\/>$/, '');
                        if (categoriesStr !== "") {
                            this.productParams.push({ 'name': 'Категория', 'value': categoriesStr });
                        }
                    }

                    if (this.productCategory > 0) {
                        this.getRelatedProducts();
                    }
                } else {
                    this.$router.push({ name: 'not-found' });
                }


            } catch (e) {
                console.log(e);
            }
        },
        async getRelatedProducts() {
            try {
                const response = await this.$http.post('search', {
                    params: {
                        categories: this.productCategory,
                        skip: 0,
                        limit: 12,
                        regionId: this.cityId
                    }
                });

                if (response.data.offers) {
                    this.relatedProducts = response.data.offers.filter(item => item.id !== this.alias);
                }

            } catch (e) {
                console.log(e);
            }

        },
        addToCart(productId) {
            this.$store.commit('addToCart', productId);
        },
        removeFromCart(productId) {
            this.$store.commit('removeFromCart', productId);
        },
        shareLink() {
            let domain = process.env.VUE_APP_BASE_URL.replace(/\/$/, '');
            let sharedLink = `https://t.me/share/url?url=${domain}${this.$route.fullPath}&text=${this.product.name}`;
            Telegram.WebApp.openTelegramLink(sharedLink);
        },
        productLink(alias) {
            return '/catalog/' + alias;
        },
        priceFormat(price) {
            return this.$filters.price_format(price);
        },
        goBackLink() {
            let link = '';
            if (this.$router.options.history.state.back) {
                let backLink = this.$router.options.history.state.back;
                let backLinkArray = backLink.split('?');
                if (backLinkArray.length > 1) {
                    link = `/catalog?${backLinkArray[1]}`;
                } else {
                    link = `/catalog?categories=${this.productCategory}`;
                }

            } else {
                link = `/catalog?categories=${this.productCategory}`;
            }
            this.$router.push(link);
        }
    },
    mounted() {
        this.getInfo();
    },
    computed: {
        ...mapGetters([
            'productQuantity',
            'productInWishlist'
        ]),
        productTotal() {
            return this.productQuantity(this.product.id);
        },
        isAvailable() {
            return Boolean(this.product.available);
        },
        onlyOffline() {
            let res = false;
            if (this.product.params) {
                let targetParam = this.product.params.find(function (param) {
                    var paramValues = Object.values(param);
                    return paramValues.some(function (value) {
                        return value.name === "Отпуск из аптеки";
                    });
                });

                res = targetParam ? (Object.values(targetParam)[0].value === "true") : false;
            }
            return res;
        }
    },
    updated() {
        if (!this.productIsLoad) {
            this.productIsLoad = true;
            if ($('.prod-images').length) {
                new Swiper(".prod-images", {
                    slidesPerView: 1,
                    spaceBetween: 15,
                    dynamicBullets: true,
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true,
                    },
                });

                $('#prod-images').lightGallery({
                    thumbnail: false,
                    slideEndAnimatoin: false,
                    download: false,
                    autoplay: false,
                    autoplayControls: false,
                    fullScreen: false,
                    share: false,
                    actualSize: false,
                    loadYoutubeThumbnail: true,
                    youtubeThumbSize: 'default',
                });

            }
            if ($('#prod-doclinks').length) {
                $('#prod-doclinks').lightGallery();
            }
        }
    }

};
</script>

<style scoped>
@import '@/assets/lib/swiper/swiper-bundle.min.css';
@import '@/assets/lib/lightgallery/lightgallery.css';

body {
    --swiper-pagination-color: var(--theme-button-color);
}

.product-main {
    margin-top: 15px;
}

.product-main>.wrapper {
    padding: 0 15px;
}

.prod-images {
    max-width: 330px;
    margin: 25px auto;
    position: relative;
}

.prod-images img {
    max-width: 100%;
    height: 320px;
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: contain;
    display: block;
    margin: 0 auto;
    border-radius: 8px;
    cursor: zoom-in;
}

.prod-images img.not-found {
    -o-object-fit: cover;
    object-fit: cover;
}

.prod-images .swiper-horizontal>.swiper-pagination-bullets,
.prod-images .swiper-pagination-bullets.swiper-pagination-horizontal {
    position: relative;
    bottom: -5px;
}

.prod-info {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 0 20px;
}

h1+small {
    font-size: 10px;
    display: inline-block;
    text-align: right;
    margin-left: 15px;
    white-space: nowrap;
}

h2,
.prod-carousel h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 10px;
}

.group-btns {
    position: relative;
    max-width: 330px;
    margin: 0 auto;
}

.back-btn,
.share-btn,
.add-to-fav {
    width: 34px;
    height: 34px;
    cursor: pointer;
    background-color: var(--theme-button-text-color);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    z-index: 10;
}

.back-btn,
.share-btn {
    top: 0px;
}

.back-btn {
    left: 0px;
}

.share-btn {
    right: 0px;
}

.add-to-fav {
    top: 60px;
    right: 0px;
}

.prod-carousel {
    margin-bottom: 100px;
}

.product-cart {
    padding: 13px;
    box-shadow: 0px 0px 12px 6px #3341551A;
    background-color: var(--theme-button-text-color);
    border-radius: 8px 8px 0px 0px;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 330px;
    width: 100%;
    margin: 0 auto;
    z-index: 100;
}

.item-buttons__wr {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.item-buttons__wr.not-available {
    justify-content: center;
    align-items: center;
    text-align: center;
}

.item-buttons__wr.not-available>.status {
    position: static;
    margin: 0 auto;
    font-weight: 600;
    font-size: 18px;
}

.item-buttons {
    width: 135px;
    display: flex;
    justify-content: space-between;
    margin: 0 0 0 auto;
    position: relative;
    transition: all var(--animation);
    border-radius: 4px;
}

.item-decr-button {
    background-color: transparent;
    pointer-events: none;
    transform: scale3d(.9, .9, 1);
    transition: transform var(--animation), visibility var(--animation);
    visibility: hidden;
    width: 34px;
    height: 34px;
    padding: 0
}

.item-incr-button {
    position: absolute;
    top: 0;
    right: 0;
    transition: width var(--animation);
    width: 100%;
    height: 100%;
    padding: 0;
    background-color: var(--theme-link-color);
    text-transform: none;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}

.item-decr-button:before,
.item-incr-button:after,
.item-incr-button:before {
    display: inline-block;
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 14px;
    height: 3px;
    background-color: transparent;
    border-radius: 1px;
    z-index: 1;
    transition: background-color var(--animation);
}

.item-incr-button:after {
    width: 3px;
    height: 14px
}

.item-incr-button .button-item-label {
    transition: transform var(--animation);
}

.item-incr-button .button-item-label {
    margin-right: 10px;
}

.button-item-label {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
    position: relative;
    z-index: 1;
}

.item-incr-button svg {
    width: 14px;
    height: 18px;
    margin-bottom: -1px;
}

.product-cart.selected .item-incr-button:after,
.product-cart.selected .item-incr-button:before,
.product-cart.selected .item-decr-button:before {
    background-color: #64748B;
}

.product-cart.selected .item-buttons {
    background-color: #E2E8F0;
    width: 95px;
}

.product-cart.selected .item-decr-button {
    pointer-events: auto;
    transform: scaleX(1);
    visibility: visible;
}

.product-cart.selected .item-incr-button {
    width: 34px;
    height: 34px;
    top: auto;
    bottom: 0;
    background-color: transparent;
}

.product-cart.selected .item-incr-button .button-item-label,
.product-cart.selected .item-incr-button svg {
    display: none;
}

.product-cart .cart-link {
    width: 32px;
    height: 34px;
    margin-left: 8px;
}

.product-cart .counter,
.product-cart .cart-link {
    display: none;
}

.product-cart.selected .counter {
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 24px;
    height: 34px;
    background-color: transparent;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #334155;
    padding: 0;
    text-align: center;
}

.product-cart.selected .cart-link {
    display: inline-block;
}

.product-price {
    display: flex;
    align-items: center;
}

.product-price ins,
.product-price del {
    text-decoration: none;
}

.product-price ins {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: var(--theme-button-color);
}

.product-price del {
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    font-size: 10px;
    color: var(--theme-hint-color);
    position: relative;
    margin-left: 10px;
}

.product-price del:after {
    content: "";
    width: 90%;
    height: 1px;
    background-color: var(--theme-hint-color);
    position: absolute;
    top: 7px;
    left: 0;
    right: 0;
    transform: rotate(-9deg);
}

.prod-sizes .size-line,
.prod-colors .color-line {
    margin: 0 0 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#prod-doclinks a,
#prod-doclinks a:after {
    font-size: 10px;
    font-weight: 600;
    text-decoration: underline;
    color: var(--theme-link-color)
}

#prod-doclinks a:after {
    content: attr(data-title);
    position: relative;
    display: inline-block
}

.size-line .current-size,
.color-line .current-color {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 20px;
    color: var(--theme-hint-color);
}

.size-line .current-size>strong,
.color-line .current-color>strong {
    color: var(--theme-text-color);
}

.sizes-list,
.colors-list {
    overflow-y: hidden;
    overflow-x: auto;
    display: flex;
    gap: 0 4px;
    margin: 0 0 15px;
}

.colors-list {
    padding-bottom: 5px;
}

.sizes-list .sizes-item,
.colors-list .colors-item {
    width: 38px;
    height: 38px;
    text-align: center;
    border-radius: 8px;
    line-height: 34px;
    background-color: var(--theme-button-text-color);
    border: 2px solid var(--theme-button-text-color);
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    color: var(--theme-text-color);
    cursor: pointer;
}

.colors-list .colors-item {
    border: none;
    width: 38px;
    height: 38px;
    position: relative;
}

.colors-list .colors-item.current {
    cursor: not-allowed;
}

.colors-list .colors-item.current:after {
    content: '';
    position: absolute;
    width: 34px;
    height: 2px;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2px;
    background-color: var(--theme-link-color);
}

.sizes-list .sizes-item.current {
    cursor: not-allowed;
    border: 2px solid var(--theme-link-color);
    color: var(--theme-link-color);
}

.prod-about {
    margin: 30px auto;
}

.prod-about article {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
}

.prod-about article+h2 {
    margin-top: 20px;
}

.prod-about article p {
    margin: 0;
}

.prod-about article p+p {
    margin-top: 10px;
}

.product-main>.prod-about-table {
    margin: 30px auto 100px;
}

.product-main>.prod-about-table h2 {
    font-weight: 400;
}

.prod-about-table .table tbody>tr:last-child>td {
    border-bottom: none;
}

header {
    display: none;
}

.product-main h1 {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 600;
}
</style>