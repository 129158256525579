<template>
    <div class="cart-list__item" :data-item-id="product.id">
        <div class="cart-list__item__wr">
            <picture @click="$router.push(productLink)">
                <img-lazy-load :img-url="product.pictures[0]" :img-title="product.name" />
            </picture>
            <div class="cart-list__item-label">
                <div class="cart-list__item-title" @click="$router.push(productLink)">
                    {{ product.name }}
                </div>
                <div class="cart-list__item-description">
                    <p v-if="product.vendor">Бренд: {{ product.vendor }}</p>
                    <p class="cart-list__item-price">
                        <span class="cart-list__item-counter">{{ product.cnt }} х</span>
                        {{ priceFormat(product.price) }}
                    </p>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    methods:{
        priceFormat(price) {
            return this.$filters.price_format(price);
        },
    },
    computed: {
        productLink() {
            return '/catalog/' + this.product.id;
        },
    }
}
</script>

<style scoped>
.cart-list__item {
    width: 100%;
    position: relative;
}

.cart-list__item:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: calc(100% - 76px);
    height: 1px;
    background-color: var(--theme-secondary-bg-color);
}

.cart-list__item+.cart-list__item {
    margin-top: 10px;
}

.cart-list__item__wr {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 25px 0 0;
    position: relative;
}

.cart-list__item .del {
    background: var(--theme-danger);
    color: var(--theme-text-color);
    height: 100%;
    text-align: center;
    font-weight: bold;
}

.cart-list__item picture {
    width: 66px;
    min-width: 66px;
    height: 66px;
    display: block;
    margin-right: 10px;
}

.cart-list__item picture>img {
    display: block;
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
    cursor: pointer;
}

.cart-list__item .cart-list__item-label {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    padding: 0;
    width: 180px;
    min-width: 180px;
    margin-right: auto;
}

.cart-list__item .cart-list__item-label .cart-list__item-title {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.cart-list__item .cart-list__item-counter {
    color: var(--theme-hint-color);
    font-weight: 600;
    white-space: nowrap;
    font-size: 12px;
    margin: 0;
    width: 30px;
    text-align: center;
}

.cart-list__item .cart-list__item-description {
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 12px;
    color: var(--theme-text-color);
    margin-top: 10px;
}

.cart-list__item .cart-list__item-description span {
    color: var(--theme-hint-color);
}

.cart-list__item .cart-list__item-description>p {
    margin: 0;
}

.cart-list__item .cart-list__item-description>p+p {
    margin-top: 5px;
}

.cart-list__item .cart-list__item-price {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    padding: 0px;
    margin-left: auto;
    white-space: nowrap;
    text-align: right;
    align-self: center;
    position: absolute;
    right: 0;
    bottom: 10px;
}

.cart-list__item.total-price__item {
    padding-top: 15px;
    border-top: 1px solid var(--theme-secondary-bg-color);
    display: flex;
    align-items: center;
}

.cart-list__item.total-price__item .cart-list__item-label,
.cart-list__item.total-price__item .cart-list__item-price {
    font-size: 17px;
}
</style>