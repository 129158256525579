<template>
    <div class="cart-list__item" :data-item-id="product.id" v-if="quanityInCart > 0">
        <div class="cart-list__item__wr">
            <picture @click="$router.push(productLink)">
                <img-lazy-load :img-url="product.pictures[0]" :img-title="product.name" />
            </picture>
            <div class="cart-list__item-label">
                <div class="cart-list__item-title" @click="$router.push(productLink)">
                    {{ product.name }}
                </div>
                <div class="cart-list__item-description">
                    <p v-if="(product.vendor && product.vendor !== '')"><span>Бренд:</span> {{ product.vendor }}</p>
                </div>
                <div class="cart-list__item-price">
                    <span class="cart-list__item-counter">{{ quanityInCart }} х</span>
                    {{ priceFormat(productAmount) }}
                </div>
            </div>
            <div class="item-buttons__wr">
                <div class="item-buttons">
                    <button class="item-decr-button button-item ripple-handler"
                        @click.prevent="removeFromCart(product.id)"></button>
                    <span class="counter">{{ quanityInCart }}</span>
                    <button class="item-incr-button button-item ripple-handler"
                        @click.prevent="addToCart(product.id)"></button>
                </div>
            </div>
            <span class="remove-cart__item" @click="$emit('remove', product.id)"></span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    methods: {
        priceFormat(price) {
            return this.$filters.price_format(price);
        },
        removeFromCart(productId) {
            if (this.quanityInCart === 1) {
                this.$emit('remove', productId);
            } else {
                this.$store.commit('removeFromCart', productId);
            }
        },
        addToCart(productId) {
            this.$store.commit('addToCart', productId);
        },
    },
    computed: {
        quanityInCart() {
            return this.$store.getters.productQuantity(this.product.id);
        },
        productLink() {
            return '/catalog/' + this.product.id;
        },
        productAmount() {
            return this.product.price * this.quanityInCart;
        }
    },
}
</script>

<style scoped>
.cart-list__item {
    width: 100%;
    position: relative;
}

.cart-list__item:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: calc(100% - 76px);
    height: 1px;
    background-color: var(--theme-secondary-bg-color);
}

.cart-list__item+.cart-list__item {
    margin-top: 10px;
}

.cart-list__item__wr {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 25px 15px 0;
    position: relative;
}

.cart-list__item .del {
    background: var(--theme-danger);
    color: var(--theme-text-color);
    height: 100%;
    text-align: center;
    font-weight: bold;
}

.cart-list__item picture {
    width: 66px;
    min-width: 66px;
    height: 66px;
    display: block;
    margin-right: 10px;
}

.cart-list__item picture>img {
    display: block;
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
    cursor: pointer;
}

.cart-list__item .cart-list__item-label {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    padding: 0;
    width: 180px;
    min-width: 180px;
    margin-right: auto;
}

.cart-list__item .cart-list__item-label .cart-list__item-title {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.cart-list__item .cart-list__item-counter {
    color: var(--theme-hint-color);
    font-weight: 600;
    white-space: nowrap;
    font-size: 12px;
    margin: 0;
    width: 30px;
    text-align: left;
}

.cart-list__item .cart-list__item-description {
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 12px;
    color: var(--theme-text-color);
    margin-top: 10px;
}

.cart-list__item .cart-list__item-description span {
    color: var(--theme-hint-color);
}

.cart-list__item .cart-list__item-description>p {
    margin: 0;
}

.cart-list__item .cart-list__item-description>p+p {
    margin-top: 5px;
}

.cart-list__item .cart-list__item-price {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    padding: 0px;
    white-space: nowrap;
    text-align: left;
    margin-top: 10px;
}

.cart-list__item.total-price__item {
    padding-top: 15px;
    border-top: 1px solid var(--theme-secondary-bg-color);
    display: flex;
    align-items: center;
}

.remove-cart__item {
    width: 15px;
    height: 15px;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23EF4444'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") no-repeat center / 10px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}

.cart-list__item.total-price__item .cart-list__item-label,
.cart-list__item.total-price__item .cart-list__item-price {
    font-size: 17px;
}

.cart-list__item .item-buttons__wr {
    margin-top: auto;
}

.cart-list__item .item-buttons {
    background-color: #E2E8F0;
    width: 75px;
    max-width: 75px;
    min-width: 75px;
    display: flex;
    justify-content: space-between;
    margin: 0 0 0 auto;
    position: relative;
    transition: all var(--animation);
    border-radius: 4px;
}

.cart-list__item .item-decr-button {
    background-color: transparent;
    transition: transform var(--animation), visibility var(--animation);
    width: 34px;
    height: 34px;
    padding: 0;
    pointer-events: auto;
    transform: scaleX(1);
    visibility: visible;
}

.cart-list__item .item-decr-button:before,
.cart-list__item .item-incr-button:after,
.cart-list__item .item-incr-button:before {
    display: inline-block;
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 12px;
    height: 2px;
    background-color: #64748B;
    border-radius: 1px;
    z-index: 1;
    transition: background-color var(--animation);
}

.cart-list__item .item-incr-button:after {
    width: 2px;
    height: 12px;
}

.cart-list__item .item-buttons .counter {
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 24px;
    height: 34px;
    background-color: transparent;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 34px;
    color: #334155;
    padding: 0;
    text-align: center;
}

.cart-list__item .item-incr-button {
    position: absolute;
    right: 0;
    transition: width var(--animation);
    padding: 0;
    text-transform: none;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    width: 34px;
    height: 34px;
    top: auto;
    bottom: 0;
    background-color: transparent;
}
</style>