<template>
    <navbar-header />
    <lk-header />
    <section class="user-info">
        <div class="wrapper" v-if="isLoggedIn">
            <form class="user-info__inner" v-if="userInfo && !loading">
                <div class="form-group" :class="{ error: isNameError }">
                    <label class="label-form" for="name">ФИО <em>*</em></label>
                    <input type="text" class="form-control" name="name" placeholder="Введите ваше имя..."
                        v-model="name">
                </div>
                <div class="form-group">
                    <label class="label-form" for="phone">Телефон</label>
                    <input type="tel" class="form-control" name="phone" placeholder="Введите ваш номер телефона..."
                        v-model="phone">
                </div>
                <div class="form-group">
                    <label class="label-form" for="email">E-mail</label>
                    <input type="email" class="form-control" name="email" placeholder="Введите вашу почту..."
                        v-model="email">
                </div>
                <div class="form-group">
                    <label class="label-form" for="address">Адрес для доставки</label>
                    <input type="text" class="form-control" name="address" placeholder="Введите адрес доставки..."
                        v-model="address">
                </div>
                <div class="form-group">
                    <label class="custom-switch">
                        <input type="checkbox" name="is_send_ads" value="1" class="custom-switch-input"
                            :checked="userInfo.is_send_ads" v-model="is_send_ads">
                        <span class="custom-switch-indicator"></span>
                        <span class="custom-switch-description">отправлять акции и скидки мне в Telegram</span>
                    </label>
                </div>
                <button class="btn w-100 btn-primary btn-save" @click.prevent="setUserData"
                    :disabled="!validateUserData">
                    Сохранить изменения
                </button>
            </form>
            <loading v-if="loading" />
        </div>
        <div class="wrapper" v-else>
            <p class="text-center lead">
                <widget-telegram-login radius="7" mode="callback" request-access="write" :telegram-login="tgBotName"
                    @callback="callbackAfterAuth" />
                <span class="mt-1">чтобы увидеть данные Вашего профиля</span>
            </p>
        </div>
    </section>
</template>
<script>
//навигация по приложению
import NavbarHeader from '@/components/NavbarHeader.vue';
//навигация по ЛК
import LkHeader from '@/components/LkHeader.vue';
//виджет авторизации ч/з ТГ
import widgetTelegramLogin from '@/components/Widgets/widgetTelegramLogin.vue';

export default {
    components: {
        NavbarHeader,
        LkHeader,
        widgetTelegramLogin
    },
    data() {
        return {
            loading: true,
            userInfo: {},
            name: '',
            phone: '',
            email: '',
            address: '',
            is_send_ads: 0
        }
    },
    methods: {
        async getUserInfo() {
            this.loading = true;
            try {

                const response = await this.$http.post('clients', {
                    params: {
                        tg_username: this.$store.state.userId,
                    }
                });

                if (response.data) {
                    this.userInfo = response.data;
                    this.name = this.userInfo.name;
                    this.phone = this.userInfo.phone;
                    this.email = this.userInfo.email;
                    this.address = this.userInfo.address;
                    this.is_send_ads = this.userInfo.is_send_ads;
                }

                this.loading = false;

            } catch (e) {
                console.log(e);
            }
        },
        async callbackAfterAuth(user) {
            this.$store.commit('setUserId', '');
            let resAuth = await this.$auth(user);
            if (resAuth) {
                this.userInfo = resAuth;
                this.name = this.userInfo.name;
                this.phone = this.userInfo.phone;
                this.email = this.userInfo.email;
                this.address = this.userInfo.address;
                this.is_send_ads = this.userInfo.is_send_ads;
            }
        },
        setUserData() {
            if (this.validateUserData) {
                this.$http.post('save-client-data', {
                    params: {
                        tg_username: this.$store.state.userId,
                        name: this.name,
                        phone: this.phone,
                        email: this.email,
                        address: this.address,
                        is_send_ads: this.is_send_ads
                    },
                }).then(function (response) {
                    if (response.data) {
                        alert('Данные успешно сохранены');
                    } else {
                        alert('Ошибка сохранения');
                    }
                }).catch(function (error) {
                    console.log(error);
                });

            }

        }
    },
    mounted() {
        if (this.isLoggedIn) {
            this.getUserInfo();
        }
    },
    computed: {
        isLoggedIn: function () {
            return this.$store.getters.isAuthenticated;
        },
        isNameError() {
            return this.name === "" ? true : false;
        },
        validateUserData() {
            let res = true;
            res = !this.isNameError;
            return res;
        }
    },

};
</script>
<style scoped>
.user-info__inner {
    width: 100%;
    max-width: 100%;
    margin: 0 auto 80px;
}

.user-info__inner .btn {
    margin-top: 3em;
    max-width: 330px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
</style>