const getters = {
    isAuthenticated: (state) => !!state.userId,
    productQuantity: state => productId => {
        const item = state.cart.find(i => i.id === productId);
        if (item) {
            return item.quantity;
        } else {
            return 0;
        }
    },
    cartProductQuanity: state => {
        let cartCnt = 0;
        if (state.cart) {
            state.cart.forEach(item => {
                cartCnt += item.quantity;
            })
        }
        return cartCnt;
    },
    cartProductIds: state => {
        let ids = '';
        if (state.cart) {
            state.cart.forEach(item => {
                ids += item.id + ',';
            })
            ids = ids.replace(/\,$/, "");
        }
        return ids;
    },
    wishlistProductIds: state => {
        let ids = '';
        if (state.wishlist) {
            state.wishlist.forEach(item => {
                ids += item.id + ',';
            })
            ids = ids.replace(/\,$/, "");
        }
        return ids;
    },
    productInWishlist: state => productId => {
        const item = state.wishlist.find(i => i.id === productId);
        if (item) {
            return 1;
        } else {
            return 0;
        }
    },
    isSetFilters: state => {
        let filters_isset = false;
        if (state.choosen_filters) {
            if (typeof state.choosen_filters === 'object') {
                filters_isset = Object.keys(state.choosen_filters).length > 0 ? true : false;
            } else {
                filters_isset = state.choosen_filters.length > 0 ? true : false;
            }
        }
        return filters_isset;
    },
    GET_RAZDEL_INFO: state => razdelId => {
        let info = [];
        if (state.razdelsList.length) {
            state.razdelsList.forEach((item) => {
                if (item !== null && item.id === razdelId) {
                    info = item;
                }
            });
        }
        return info;
    },
    GET_CURRENT_RAZDEL_NAME: state => razdelId => {
        let res = '';
        if (state.razdelsList.length) {
            state.razdelsList.forEach((item) => {
                if (item !== null && Number(item.id) === Number(razdelId)) {
                    res = item.title;
                }
            });
        }
        return res;
    },
    GET_MAIN_RAZDEL_TREE: state => {
        let res = [];

        const categoriesTree = (categories) => {
            const tree = [];

            // Создаем хеш-таблицу для быстрого доступа к каждому объекту по его id
            const categoryMap = {};

            categories.forEach(category => {
                // Создаем новый объект для каждой категории
                const node = {
                    ...category,
                    children: []
                };

                // Добавляем объект в хеш-таблицу
                categoryMap[node.id] = node;

                const parentId = category.parentId;

                // Если у категории есть родительский id, добавляем ее как дочерний объект родительской категории
                if (parentId !== null) {
                    const parentCategory = categoryMap[parentId];
                    if (parentCategory) {
                        parentCategory.children.push(node);
                    }
                } else {
                    // Если у категории нет родительского id, она является корневой категорией
                    tree.push(node);
                }
            });

            return tree;

        }

        if (state.razdelsList.length) {
            res = categoriesTree(state.razdelsList);
        }

        return res;
    },
    GET_RAZDEL_PARENT_ID: state => razdelId => {
        let res = 0;
        if (state.razdelsList.length) {
            const item = state.razdelsList.find(i => i.id === razdelId);
            if (item) {
                res = item.parentId;
            }
        }
        return res;
    }
};

export default getters;