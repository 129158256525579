<template>
    <header>
        <div class="wrapper">
            <nav class="nav" data-nav>
                <ul class="nav__items">
                    <li class="nav__item">
                        <router-link active-class="current" to="/catalog" class="nav__item-btn">
                            <svg class="nav__item-icon" width="14" height="11" viewBox="0 0 14 11" fill="none">
                                <path
                                    d="M1 4.75C0.585786 4.75 0.25 5.08579 0.25 5.5C0.25 5.91421 0.585786 6.25 1 6.25V4.75ZM13 6.25C13.4142 6.25 13.75 5.91421 13.75 5.5C13.75 5.08579 13.4142 4.75 13 4.75V6.25ZM1 8.75C0.585786 8.75 0.25 9.08579 0.25 9.5C0.25 9.91421 0.585786 10.25 1 10.25V8.75ZM13 10.25C13.4142 10.25 13.75 9.91421 13.75 9.5C13.75 9.08579 13.4142 8.75 13 8.75V10.25ZM1 0.75C0.585786 0.75 0.25 1.08579 0.25 1.5C0.25 1.91421 0.585786 2.25 1 2.25V0.75ZM13 2.25C13.4142 2.25 13.75 1.91421 13.75 1.5C13.75 1.08579 13.4142 0.75 13 0.75V2.25ZM1 6.25H13V4.75H1V6.25ZM1 10.25H13V8.75H1V10.25ZM1 2.25H13V0.75H1V2.25Z"
                                    fill="currentColor" />
                            </svg>
                            <span class="nav__item-text">Каталог</span>
                        </router-link>
                    </li>
                    <li class="nav__item">
                        <router-link active-class="current" to="/cart" class="nav__item-btn">
                            <span v-if="(cartCnt > 0)">
                                <span class="cart_cnt" :class="{ notempty: cartCnt > 0 }">{{ cartCnt }}</span>
                            </span>
                            <svg class="nav__item-icon" width="16" height="20" viewBox="0 0 16 20" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M1.00035 7.3C0.987 6.31988 1.77024 5.51426 2.75035 5.5H13.2503C14.2305 5.51426 15.0137 6.31988 15.0004 7.3V15.4C15.0276 17.3605 13.4608 18.972 11.5004 19H4.50035C2.53989 18.972 0.973096 17.3605 1.00035 15.4V7.3Z"
                                    stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                <path
                                    d="M11.5004 8.2V4.6C11.5276 2.63953 9.96081 1.02797 8.00035 1C6.03989 1.02797 4.4731 2.63953 4.50035 4.6V8.2"
                                    stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                            <span class="nav__item-text">Корзина</span>
                        </router-link>
                    </li>
                    <li class="nav__item">
                        <router-link active-class="current" to="/lk" class="nav__item-btn" exact>
                            <svg class="nav__item-icon" width="14" height="16" viewBox="0 0 14 16" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M9.47694 10H4.52294C3.06488 10.0724 1.7731 10.963 1.18693 12.3C0.479935 13.688 1.89093 15 3.53193 15H10.4679C12.1099 15 13.5209 13.688 12.8129 12.3C12.2268 10.963 10.935 10.0724 9.47694 10Z"
                                    stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M9.99994 4C9.99994 5.65685 8.65679 7 6.99994 7C5.34308 7 3.99994 5.65685 3.99994 4C3.99994 2.34315 5.34308 1 6.99994 1C7.79559 1 8.55865 1.31607 9.12126 1.87868C9.68387 2.44129 9.99994 3.20435 9.99994 4Z"
                                    stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                            <span class="nav__item-text">Профиль</span>
                        </router-link>
                    </li>
                </ul>
            </nav>
        </div>
    </header>
</template>
<script>
export default {
    computed: {
        cartCnt() {
            return this.$store.getters.cartProductQuanity;
        }
    }
}
</script>
<style>
header {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    width: 100%;
    margin: 0 auto;
    max-width: 330px;
}

header>.wrapper {
    padding: 0;
}

header nav {
    width: 100%;
    padding: 0.75em 2.5em;
}

header nav a {
    color: var(--theme-text-color);
    transition: background-color var(--trans-dur), color var(--trans-dur);
}

.icon1-1 {
    transform-origin: 12px 6px;
}

.icon3-1 {
    transform-origin: 12px 1px;
}

.icon4-3 {
    transform-origin: 12px 10px;
}

.icon5-1 {
    transform-origin: 12px 20px;
}

.nav {
    background-color: var(--theme-button-text-color);
    box-shadow: 0px 0px 12px 6px rgba(51, 65, 85, 0.1);
    border-radius: 8px 8px 0px 0px;
    transform-origin: 50% 133%;
}

.nav--tilt1 {
    animation: tilt1 0.6s ease-in-out;
}

.nav--tilt2 {
    animation: tilt2 0.6s ease-in-out;
}

.nav--tilt3 {
    animation: tilt3 0.6s ease-in-out;
}

.nav--tilt4 {
    animation: tilt4 0.6s ease-in-out;
}

.nav--tilt5 {
    animation: tilt5 0.6s ease-in-out;
}

.nav__items {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin: 0;
}

.nav__item {
    position: relative;
}

.nav__item-btn {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    color: #64748B;
    width: 3.3em;
    height: 3em;
    text-decoration: none;
    position: relative;
}

.nav__item-btn:focus,
.nav__item-btn:hover,
.nav__item-btn.current,
.nav__item-btn[aria-describedby=current] {
    color: var(--theme-link-color);
}

.nav__item-btn:focus {
    outline: transparent;
}

.nav__item-icon {
    display: block;
    margin: 0 auto 4px;
    overflow: visible;
    width: 1.5em;
    height: 1.5em;
    transition: transform var(--trans-dur) var(--trans-timing);
}

.nav__item-icon g,
.nav__item-icon path,
.nav__item-icon rect {
    animation-duration: calc(var(--trans-dur) * 3);
    animation-timing-function: var(--trans-timing);
}

.nav__item-text {
    display: block;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 14px;
    text-align: center;
}

.nav__item-btn:focus .nav__item-icon,
.nav__item-btn:hover .nav__item-icon {
    transform: translateY(-0.3em);
}

.nav__item-btn:focus .icon1-1,
.nav__item-btn:hover .icon1-1 {
    animation-name: icon1-1;
}

.nav__item-btn:focus .icon2-1,
.nav__item-btn:hover .icon2-1 {
    animation-name: icon2-1;
}

.nav__item-btn:focus .icon2-2,
.nav__item-btn:hover .icon2-2 {
    animation-name: icon2-2;
}

.nav__item-btn:focus .icon2-3,
.nav__item-btn:hover .icon2-3 {
    animation-name: icon2-3;
}

.nav__item-btn:focus .icon2-4,
.nav__item-btn:hover .icon2-4 {
    animation-name: icon2-4;
}

.nav__item-btn:focus .icon3-1,
.nav__item-btn:hover .icon3-1 {
    animation-name: icon3-1;
}

.nav__item-btn:focus .icon4-1,
.nav__item-btn:hover .icon4-1 {
    animation-name: icon4-1;
}

.nav__item-btn:focus .icon4-2,
.nav__item-btn:hover .icon4-2 {
    animation-name: icon4-2;
}

.nav__item-btn:focus .icon4-3,
.nav__item-btn:hover .icon4-3 {
    animation-name: icon4-3;
}

.nav__item-btn:focus .icon5-1,
.nav__item-btn:hover .icon5-1 {
    animation-name: icon5-1;
}

/* `:focus-visible` support */
@supports selector(:focus-visible) {
    .nav__item-btn:focus {
        color: currentColor;
    }

    .nav__item-btn:focus-visible,
    .nav__item-btn:hover,
    .nav__item-btn[aria-describedby=current] {
        color: var(--theme-button-color);
    }

    .nav__item-btn:focus .nav__item-icon {
        transform: translateY(0);
    }

    .nav__item-btn:focus-visible .nav__item-icon,
    .nav__item-btn:hover .nav__item-icon {
        transform: translateY(-0.3em);
    }
}

.cart_cnt {
    font-style: normal;
    font-weight: 700;
    font-size: 6px;
    line-height: 10px;
    width: 10px;
    height: 10px;
    color: var(--theme-button-text-color);
    background: var(--theme-button-color);
    border-radius: 100%;
    text-align: center;
    position: absolute;
    top: 14px;
    display: none;
    z-index: 1;
}

.cart_cnt.notempty {
    display: block;
}
</style>