<template>
    <navbar-header />
    <lk-header />
    <section class="order-list">
        <div class="wrapper" v-if="isLoggedIn">
            <div class="lb_accordeon" id="lb_accordeon" v-if="(orders.length > 0)">
                <div class="order-list__inner lb_accordeon__item">

                    <div v-for="(item, index) in orders" class="order-item lb_item"
                        :class="{ 'lb_ext-active': (currentFaq === index), 'no-products': (!item.products) }">
                        <div class="order-item__head">
                            <h3>{{ `#${item.id} ${item.name}` }}</h3>
                            <span class="order-status danger" v-if="isCanceled(item.status)">{{ item.status }}</span>
                            <span class="order-amount">
                                {{ priceFormat(item.price_sum) }}
                                <small v-if="item.price_delivery">{{ priceFormat(item.price_delivery) }}</small>
                            </span>
                            <dl>
                                <dt>Способ получения:</dt>
                                <dd>{{ item.delivery }}</dd>
                            </dl>
                            <dl>
                                <dt>Способ оплаты</dt>
                                <dd>{{ item.payment }}</dd>
                            </dl>
                            <div class="btns-group" v-if="item.products">
                                <button class="btn btn-outline-primary btn-with-icon"
                                    @click="repeatOrder(item.products)">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <use xlink:href="#refresh-icon" href="#refresh-icon"></use>
                                    </svg>
                                    <span>Повторить заказ</span>
                                </button>
                                <button class="btn btn-link" @click="faqTrigger(index)">
                                    {{(currentFaq === index) ? 'Скрыть товары' : 'Посмотреть товары'}}
                                </button>
                            </div>
                        </div>
                        <transition mode="out-in" name="faq-fade" v-on:before-enter="beforeEnterFaq"
                            v-on:enter="enterFaq">
                            <div class="order-item__products lb_item__copy"
                                v-show="item.products && (currentFaq === index)">
                                <h4>Товары в заказе</h4>
                                <div v-if="(item.products)" class="cart-list">
                                    <order-product v-for="product in item.products" :key="product.id"
                                        :product="product" />
                                </div>
                            </div>
                        </transition>
                    </div>

                </div>
            </div>
            <p v-if="(orders.length === 0 && !loading)" class="lead text-center">{{ empty_text }}</p>
            <div ref="observer" class="observer">
                <loading v-if="loading" />
            </div>
        </div>
        <div class="wrapper" v-else>
            <p class="text-center lead">
                <widget-telegram-login radius="7" mode="callback" request-access="write" :telegram-login="tgBotName"
                    @callback="callbackAfterAuth" />
                <span class="mt-1">чтобы увидеть список Ваших заказов</span>
            </p>
        </div>
    </section>
</template>

<script>
//навигация по приложению
import NavbarHeader from '@/components/NavbarHeader.vue';
//навигация по ЛК
import LkHeader from '@/components/LkHeader.vue';
//товар в заказе
import OrderProduct from '@/components/OrderProduct.vue';
//виджет авторизации ч/з ТГ
import widgetTelegramLogin from '@/components/Widgets/widgetTelegramLogin.vue';

export default {
    components: {
        NavbarHeader,
        LkHeader,
        OrderProduct,
        widgetTelegramLogin
    },
    data() {
        return {
            empty_text: "Список заказов пуст",
            loading: true,
            currentFaq: -10,
            orders: [],
            repeatOrderId: 0,
            offset: 0,
            pageNum: 1,
            limit: 4,
        }
    },
    methods: {
        getRandomIntInclusive: function (min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        faqTrigger: function (newFaq) {
            if (newFaq === this.currentFaq) {
                this.currentFaq = -10
            } else {
                this.currentFaq = newFaq
            }
        },
        beforeEnterFaq: function (_t) {
            _t.style.display = 'block'
            _t.style.maxHeight = null
            _t.myHeight = _t.offsetHeight
            _t.style.maxHeight = 0
            _t.style.display = null
        },
        enterFaq: function (_t) {
            _t.style.maxHeight = _t.myHeight + 'px'
        },
        async getUserOrders() {
            this.loading = true;
            try {
                const response = await this.$http.post('orders', {
                    params: {
                        tg_username: this.$store.state.userId,
                        skip: this.offset,
                        limit: this.limit,
                        regionId: this.cityId
                    }
                });

                if (response.data) {
                    if (this.pageNum === 1) {
                        this.orders = response.data;
                    } else {
                        this.orders = [...this.orders, ...response.data];
                    }

                    if (response.data.length < this.limit) {
                        this.loadMore = false;
                    } else {
                        this.loadMore = true;
                    }

                    this.loading = false;

                } else {
                    this.loading = false;
                    this.loadMore = false;
                }


            } catch (e) {
                console.log(e);
            }
        },
        loadMoreData() {
            this.pageNum++;
            this.offset = this.pageNum;
            this.getUserOrders();
        },
        repeatOrder(orderProducts) {
            if (orderProducts) {
                let newProducts = [];
                orderProducts.forEach((item) => {
                    newProducts.push({ id: item.id.toString(), quantity: item.cnt });
                });
                this.$store.commit('addToCartMultiply', newProducts);
                this.$router.push('/cart');
            }
        },
        async callbackAfterAuth(user) {
            this.$store.commit('setUserId', '');
            let resAuth = await this.$auth(user);
            if (resAuth) {
                this.userInfo = resAuth;
                this.getUserOrders();
            }
        },
        isCanceled(statusStr) {
            return statusStr === 'Отменён';
        },
        priceFormat(price) {
            return this.$filters.price_format(price);
        },
    },
    mounted() {
        if (this.isLoggedIn) {

            this.getUserOrders();

            //===loading by scroll START===
            const scrollOptions = {
                rootMargin: '0px',
                threshold: 1.0
            }
            const scrollCallback = (enteries, observer) => {
                if (enteries[0].isIntersecting && this.loadMore) {
                    this.loading = true;
                    this.loadMoreData();
                }
            }
            const observer = new IntersectionObserver(scrollCallback, scrollOptions);
            observer.observe(this.$refs.observer);
            //===loading by scroll END=====

        }
    },
    computed: {
        isLoggedIn: function () {
            return this.$store.getters.isAuthenticated;
        }
    },

};
</script>

<style scoped>
@-webkit-keyframes accordeonAni {
    0% {
        max-height: 0;
    }
}

@keyframes accordeonAni {
    0% {
        max-height: 0;
    }
}

.lb_accordeon .lb_item {
    display: block;
}

.lb_accordeon .lb_item__copy {
    margin: 0;
    padding: 0;
}

.faq-fade-enter {
    max-height: 0;
    display: block;
}

.faq-fade-enter-active {
    -webkit-animation: accordeonAni 400ms ease-out;
    animation: accordeonAni 400ms ease-out;
    overflow: hidden;
}

.faq-fade-leave {
    display: block;
}

.faq-fade-leave-to {
    display: block;
}

.faq-fade-leave-active {
    -webkit-animation: accordeonAni 400ms ease-out;
    animation: accordeonAni 400ms ease-out;
    animation-direction: reverse;
    overflow: hidden;
}

.order-list {
    margin-bottom: 80px;
}

.order-item {
    padding: 8px 12px 60px;
    border-radius: 8px;
    position: relative;
    background-color: var(--theme-button-text-color);
    box-shadow: 0 0 12px 6px #3341551a;
    cursor: pointer;
    transition: background-color 300ms ease-out;
}

.order-item.no-products {
    padding-bottom: 8px;
}

.order-item+.order-item {
    margin-top: 10px;
}

.order-item__head {
    padding-right: 68px;
}

.order-item h3,
.order-item h4 {
    margin: 0 0 15px;
}

.order-item h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
}

.order-amount {
    position: absolute;
    top: 8px;
    right: 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
}

.order-amount>small {
    font-weight: 400;
    font-size: 10px;
    color: var(--theme-hint-color);
    display: block;
    margin-top: 3px;
}

.order-status {
    position: absolute;
    top: 28px;
    right: 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: #10B981;
}

.order-status.danger {
    color: #eb5757;
}

.order-item dl {
    display: flex;
    align-items: flex-start;
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
}

.order-item dl+dl {
    margin-top: 5px;
}

.order-item dl dt {
    color: var(--theme-hint-color);
    margin-right: 5px;
}

.order-item dl dd {
    margin-inline-start: 0px;
}

.order-item .order-item__products {
    padding: 35px 0 0;
}

.order-item .cart-list__item .cart-list__item-price {
    text-align: left;
}

.order-item .cart-list__item .cart-list__item-counter {
    width: 20px;
    margin-right: 5px;
}

.order-item .cart-list__item .cart-list__item-description>p {
    margin: 5px 0 0;
}

.order-item .cart-list {
    margin-bottom: 0;
}

.order-item .btns-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 10px 10px;
}

.order-item .btn-link {
    background-color: transparent;
    text-transform: none;
    font-weight: 500;
    margin: 0;
}

.cart-list .cart-list__item:last-child::after {
    display: none;
}

.order-list__inner {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
}
</style>