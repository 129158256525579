<template>
    <img :class="{ 'not-found': notFound }" v-lazy="{ src: imgUrl, loading: loadingImg, error: errorImg }"
        :alt="imgTitle" />
</template>

<script>
export default {
    name: 'img-lazy-load',
    data() {
        return {
            loadingImg: require('@/assets/img/loading.gif'),
            errorImg: require('@/assets/img/loading-error.png'),
        }
    },
    props: {
        imgUrl: {
            type: String,
            required: true
        },
        imgTitle: {
            type: String,
            required: false,
            default: process.env.VUE_APP_NAME
        },
        notFound: {
            type: Boolean,
            default: false
        }
    }
}
</script>