<template>
    <navbar-header />
    <div class="wrapper">
        <section class="not_found_page text-center">
            <svg class="not_found" width="102" height="102" viewBox="0 0 102 102">
                <use href="#not_found" xlink:href="#not_found"></use>
            </svg>
            <h1>Здесь что-то не так</h1>
            <p>
                К сожалению, такой страницы не существует в этой версии вселенной.
            </p>
            <p>
                <router-link :to="catalogLink" class="default-btn">Вернуться в каталог</router-link>
            </p>
        </section>
    </div>
</template>

<script>
//навигация по приложению
import NavbarHeader from '@/components/NavbarHeader.vue';

export default {
    components: {
        NavbarHeader,
    },
    computed: {
        catalogLink() {
            return '/catalog';
        },
    }
}
</script>

<style scoped>
h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin: 35px 0 15px;
}

svg.not_found {
    margin-top: 75px;
}

.default-btn {
    margin-top: 60px;
}
</style>