import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

//Ленивая загрузка Views
//Каждый из этих компонентов будет загружен только тогда, когда пользователь впервые запросит указанный маршрут
import CatalogPage from '@/pages/CatalogPage';
import ProductPage from '@/pages/ProductPage';
import CartPage from '@/pages/CartPage';
import LkPage from '@/pages/LkPage';
import LkOrdersPage from '@/pages/LkOrdersPage';
import LkUserPage from '@/pages/LkUserPage';
import SuccessOrderPage from '@/pages/SuccessOrderPage';
import NotFoundPage from '@/pages/NotFoundPage';

const routes = [
  {
    path: '/',
    name: 'home',
    component: CatalogPage,
  },
  {
    path: '/catalog/',
    name: 'catalog',
    component: CatalogPage,
  },
  {
    path: '/catalog/:productAlias',
    name: 'product',
    component: ProductPage,
  },
  {
    path: '/cart',
    name: 'cart',
    component: CartPage,
  },
  {
    path: '/lk',
    component: LkPage,
    children: [
      {
        path: '',
        component: LkPage,
        redirect: { name: 'lk_orders' }
      },
      {
        path: 'orders',
        name: 'lk_orders',
        component: LkOrdersPage,
      },
      {
        path: 'profile',
        name: 'lk_user',
        component: LkUserPage,
      }
    ]
  },
  {
    path: '/success-order',
    name: 'success-order',
    component: SuccessOrderPage
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFoundPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  store.commit('updateStateParam', { title: 'isPopupOpen', value: false });
  window.scrollTo(0, 0);
  next();
});

export default router;