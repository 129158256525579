<template>
    <navbar-header v-if="(appMode === 2)" />
    <section class="order">
        <div class="wrapper">
            <div class="section-header">
                <h1>Ваш заказ</h1>
                <button class="cart-clear" @click="clearCart()" v-if="(products.length > 0)">
                    <span>Удалить всё</span>
                    <svg width="11" height="12" viewBox="0 0 11 12" fill="none">
                        <use xlink:href="#del-icon" href="#del-icon"></use>
                    </svg>
                </button>
                <router-link :to="catalogLink" v-else-if="(products.length === 0 && !loading)">
                    <svg width="7" height="12" viewBox="0 0 7 12" fill="none"
                        style="width: 5px; transform:rotate(180deg)">
                        <use xlink:href="#angle-right" href="#angle-right"></use>
                    </svg>
                    <span>Вернуться к покупкам</span>
                </router-link>
            </div>
        </div>
        <div class="wrapper" v-if="(products.length > 0 && orderId === 0)">
            <section class="cart-wr" :class="{ 'empty-cart': (products.length === 0) }">
                <div v-if="(products.length > 0)" class="cart-list">
                    <transition-group name="anim-list">
                        <cart-product v-for="product in products" :key="product.id" :product="product"
                            @remove="removeFromCart" />
                    </transition-group>
                    <div class="cart-list__item total-price__item">
                        <div class="cart-list__item-label">Итого:</div>
                        <div class="cart-list__item-price">{{ cartTotalAmount }}</div>
                    </div>
                </div>
            </section>
            <form v-if="(products.length > 0 && $store.state.userId !== '')" class="order-form">
                <div class="form-group">
                    <label for="payment">Способ получения <em>*</em></label>
                    <div class="selectgroup">
                        <label class="selectgroup-item">
                            <input type="radio" name="delivery" value="1" class="selectgroup-input"
                                :checked="(delivery == 1)" v-model="delivery">
                            <span class="selectgroup-button">Самовывоз</span>
                        </label>
                        <label class="selectgroup-item">
                            <input type="radio" name="delivery" value="2" class="selectgroup-input"
                                :checked="(delivery == 2)" v-model="delivery">
                            <span class="selectgroup-button">Доставка</span>
                        </label>
                    </div>
                </div>
                <div class="form-group" v-if="(delivery == 1)" :class="{ 'error': isPvzError }">
                    <label for="pvz_address">Из какой точки вывоза заберёте <em>*</em></label>
                    <select name="pvz_address" class="custom-select" v-model="pvz_address">
                        <option value="" selected>Выбрать</option>
                        <option v-for="pvz in pvz_list" :key="pvz.id" :value="pvz.text" :label="pvz.text">{{ pvz.text }}
                        </option>
                    </select>
                </div>
                <div class="mt-1 mb-15 data-for-delivery" v-if="(delivery == 2)">
                    <div class="delivery-price">
                        <small>Стоимость доставки:</small>
                        <span>{{ priceFormat(delivery_price) }}</span>
                    </div>
                    <div class="form-group" :class="{ error: isNameError }">
                        <label class="label-form" for="name">ФИО <em>*</em></label>
                        <input type="text" class="form-control" name="name" placeholder="Введите ваше имя..."
                            v-model="userInfo.name">
                    </div>
                    <div class="form-group" :class="{ error: isPhoneError }">
                        <label class="label-form" for="phone">Телефон <em>*</em></label>
                        <input type="tel" class="form-control" name="phone" placeholder="Введите ваш номер телефона..."
                            v-model="userInfo.phone">
                    </div>
                    <div class="form-group" :class="{ error: isEmailError }">
                        <label class="label-form" for="email">E-mail</label>
                        <input type="email" class="form-control" name="email" placeholder="Введите вашу почту..."
                            v-model="userInfo.email">
                    </div>
                    <div class="form-group" :class="{ error: isAddressError }">
                        <label class="label-form" for="address">Адрес <em>*</em></label>
                        <input type="text" class="form-control" name="address" placeholder="Введите адрес доставки..."
                            v-model="userInfo.address">
                    </div>
                </div>
                <div class="form-group">
                    <label for="payment">Способ оплаты <em>*</em></label>
                    <select name="payment" class="custom-select" v-model="payment">
                        <option value="1" selected>При получении</option>
                    </select>
                </div>
                <div class="form-group">
                    <textarea class="order-comment-field form-control" rows="1" placeholder="Комментарий к заказу…"
                        v-model="comment" name="comment"></textarea>
                </div>
                <button class="btn w-100 mt-1 btn-primary btn-save" v-if="(appMode === 2)" @click.prevent="saveOrder"
                    :disabled="!validateOrderData">
                    Оформить заказ
                </button>
            </form>
            <p class="text-center lead"
                v-else-if="(appMode === 2 && products.length > 0 && $store.state.userId === '')">
                <widget-telegram-login radius="7" mode="callback" request-access="write" :telegram-login="tgBotName"
                    @callback="callbackAfterAuth" />
                <span class="mt-1">чтобы оформить заказ</span>
            </p>
        </div>
        <div class="wrapper" v-if="(products.length === 0 && !loading)">
            <p class="lead text-center">{{ empty_text }}</p>
        </div>
        <div class="wrapper" v-if="(orderId === -1)">
            <div class="section-header">
                <h1>Ваш заказ</h1>
                <router-link :to="catalogLink">Вернуться к покупкам</router-link>
            </div>
            <p class="lead text-center">Что-то пошло не так. Заказ не создан.</p>
        </div>
        <loading v-if="loading" />
    </section>
</template>

<script>
//навигация по приложению
import NavbarHeader from '@/components/NavbarHeader.vue';
//блок с товаром
import CartProduct from '@/components/CartProduct.vue';
//виджет авторизации ч/з ТГ
import widgetTelegramLogin from '@/components/Widgets/widgetTelegramLogin.vue';
import PvzAddress from '@/store/pvz_address.json';

export default {
    components: {
        NavbarHeader,
        CartProduct,
        widgetTelegramLogin
    },
    data() {
        return {
            loading: true,
            empty_text: "В корзине пока ничего нет",
            cart: this.$store.state.cart,
            appMode: this.$store.state.$app_mode,
            orderId: 0,
            payment: 1,
            delivery: 1,
            delivery_price: process.env.VUE_APP_DELIVERY_PRICE,
            comment: '',
            products: [],
            userInfo: [],
            pvz_address: "",
            pvz_list: PvzAddress
        }
    },
    methods: {
        async getProducts() {
            try {
                const response = await this.$http.post('goods-by-ids', {
                    params: {
                        ids: this.cartProductIds,
                        regionId: this.cityId
                    },
                });

                if (response.data.offers) {
                    this.products = response.data.offers;
                } else {
                    this.$store.commit('resetCart');
                    this.empty_text = "Возможно товары, которые были у вас в корзине временно недоступны, поэтому сейчас ваша корзина пуста.";
                }
                this.loading = false;

            } catch (e) {
                console.log(e);
            }
        },
        removeFromCart(productId) {
            this.$store.commit('removeFromCartAtAll', productId);
            this.products = this.products.filter(i => i.id !== productId);
            this.cart = this.$store.state.cart;
            if (this.cart.length === 0 && this.appMode === 1) {
                Telegram.WebApp.MainButton.setParams({
                    text: 'ОФОРМИТЬ ЗАКАЗ',
                });
                Telegram.WebApp.MainButton.hide();
            }
        },
        async getUserInfo() {
            try {

                const response = await this.$http.post('clients', {
                    params: {
                        tg_username: this.$store.state.userId
                    }
                });

                if (response.data) {
                    this.userInfo = response.data;
                }

            } catch (e) {
                console.log(e);
            }
        },
        saveOrder() {

            if (this.validateOrderData) {

                this.$http.post('create-order', {
                    params: {
                        cart: this.cart,
                        client_data: this.userInfo,
                        comment: this.comment,
                        delivery: this.delivery,
                        delivery_price: this.delivery_price,
                        pvz_address: this.pvz_address,
                        payment: this.payment,
                        regionId: this.cityId
                    }
                })
                    .then((response) => {
                        if (response.data) {
                            this.orderId = response.data.order_id;
                            if (typeof this.orderId === 'undefined' || this.orderId < 0) {
                                alert("Ошибка оформления заказа");
                            } else {
                                this.$store.commit('resetCart');
                                if (this.appMode === 1) {
                                    Telegram.WebApp.MainButton.hide();
                                }
                                this.$router.push({ 'name': 'success-order', 'query': { 'order_id': this.orderId } });
                            }
                        } else {
                            this.orderId = -1;
                            Telegram.WebApp.MainButton.hide();
                        }
                    }).catch((e) => {
                        console.log(e);
                    });
            }

        },
        setTGMainBtnEvent() {
            this.saveOrder();
        },
        async callbackAfterAuth(user) {
            this.$store.commit('setUserId', '');
            let resAuth = await this.$auth(user);
            if (resAuth) {
                this.userInfo = resAuth;
            }
        },
        priceFormat(price) {
            return this.$filters.price_format(price);
        },
        clearCart() {
            this.$store.commit('resetCart');
            this.products = [];
            if (this.appMode === 1) {
                Telegram.WebApp.MainButton.hide();
            }
        }
    },
    mounted() {

        if (this.cart.length > 0) {
            this.getProducts();
            if (this.isLoggedIn) {
                this.getUserInfo();
            }
        } else {
            this.loading = false;
        }

        if (this.appMode === 1) {
            Telegram.WebApp.MainButton.onClick(this.setTGMainBtnEvent);
        }

    },
    computed: {
        catalogLink() {
            return '/catalog';
        },
        isLoggedIn: function () {
            return this.$store.getters.isAuthenticated;
        },
        cartProductIds() {
            return this.$store.getters.cartProductIds;
        },
        cartTotalAmount() {
            let cartTotal = 0;
            this.products.forEach((item) => {
                cartTotal += (parseFloat(item.price) * this.$store.getters.productQuantity(item.id));
            });
            if (this.delivery == 2) {
                cartTotal += Number(this.delivery_price);
            }
            return this.priceFormat(cartTotal);
        },
        isEmailValid() {
            var emailCheckRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return emailCheckRegex.test(this.userInfo.email);
        },
        isEmailError() {
            return !this.isEmailValid;
        },
        isNameError() {
            return this.userInfo.name === "" ? true : false;
        },
        isPhoneError() {
            return this.userInfo.phone === "" ? true : false;
        },
        isAddressError() {
            return this.userInfo.address === "" ? true : false;
        },
        isPvzError() {
            return this.pvz_address === "" ? true : false;
        },
        validateOrderData() {
            let res = true;
            if (this.delivery == 2) {
                res = !this.isNameError && !this.isPhoneError && !this.isAddressError && !this.isEmailError;
            } else {
                res = !this.isPvzError;
            }
            return res;
        }
    },

};
</script>

<style scoped>
.sdek {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 15px;
}

.sdek-label {
    margin: 0;
    min-width: 80px;
    width: 80px;
    padding-right: 20px;
}

.sdek-label>svg {
    display: block;
    width: 100%;
}

.delivery-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    margin-bottom: 10px;
}

.delivery-price>small {
    font-size: 12px;
}

.delivery-price>span {
    font-size: 16px;
}

.sdek-address__btn {
    text-transform: none;
    justify-content: center;
    gap: 0 15px;
    margin-bottom: 20px;
    box-shadow: 0 0 12px 6px #3341551a;
}

.sdek-address__choosen {
    margin-bottom: 25px;
}

.sdek-address__choosen>small {
    font-size: 12px;
    font-weight: 500;
    display: block;
    margin-bottom: 5px;
}

.sdek-address__choosen>span {
    font-size: 14px;
    font-weight: 400;
    display: block;
}

.order-comment-field {
    margin-top: 10px;
}

.anim-list-item {
    background-color: #fff;
}

.anim-list-enter-active,
.anim-list-leave-active {
    transition: transform 0.3s ease, opacity 0.5s ease;
}

.anim-list-enter-from,
.anim-list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}

.anim-list-move {
    transition: transform 0.8s ease;
}

.cart-list {
    margin-bottom: 40px;
    width: 100%;
}

.centered-block {
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
    text-align: center;
}

.section-header {
    margin: 15px 0 0;
    width: 100%;
}

.section-header h1 {
    font-weight: 600;
}

.section-header a,
.section-header small,
.section-header button {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    text-transform: none;
    white-space: nowrap;
    color: var(--theme-hint-color);
}

.section-header button {
    display: inline-block;
    background-color: transparent;
    padding: 0;
    border: none;
    height: auto;
    border-radius: 0;
}

.section-header button.cart-clear {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0 5px;
}

.total-price__item {
    margin-top: 20px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.total-price__item .cart-list__item-label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
}

.total-price__item .cart-list__item-price {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
}

.selectgroup {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 0 10px;
}

.selected-radio {
    width: 50%;
}

.selectgroup-item {
    width: 50%;
}

.selectgroup-item .selectgroup-input {
    display: none;
}

.selectgroup-button {
    height: 32px;
    line-height: 28px;
    border: 2px solid transparent;
    background-color: var(--theme-button-text-color);
    border-radius: 8px;
    width: 100%;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: var(--theme-hint-color);
    cursor: pointer;
}

.selectgroup-input:checked+.selectgroup-button {
    border-color: var(--theme-button-color);
    background-color: transparent;
    color: var(--theme-button-color);
}

.order-form {
    margin-bottom: 80px;
}
</style>