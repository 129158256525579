<template>
    <section class="catalog-header">
        <div class="wrapper">
            <div class="section-header">
                <h1>{{ categoryName }}</h1>
                <small>{{ totalCountString }}</small>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'catalog-header',
    props: {
        categoryName: {
            type: String,
            default: 'Каталог',
        },
        totalCountString: {
            type: String,
            required: false
        }
    }
};
</script>
<style scoped>
.catalog-header {
    margin-top: 15px;
}

.catalog-header>.wrapper {
    padding: 0 15px;
}

.catalog-header h1 {
    font-weight: 600;
    margin-bottom: 30px;
}
</style>