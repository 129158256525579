<template>
    <router-link :to="productLink(product.id)" class="swiper-slide list__item js-item"
        :class="{ 'not-available': !isAvailable }">
        <span class="status">{{ isAvailable? 'в наличии': 'нет в наличии' }}</span>
        <picture>
            <img-lazy-load :img-url="galleryImg" :img-title="product.name" :not-found="imgNotFound" />
        </picture>
        <h3>{{ product.name }}</h3>
        <small>{{ product.vendor }}</small>
        <div class="list__item-cart js-item" :class="{ selected: productTotal > 0 }">
            <div class="item-buttons__wr">
                <div class="list__item-price" v-if="(productPrice)">
                    <ins>{{ priceFormat(productPrice) }}</ins>
                    <del v-if="(productOldPrice)">{{ priceFormat(productOldPrice) }}</del>
                </div>
                <div class="item-buttons" v-if="isShowCart && isAvailable">
                    <button class="item-decr-button button-item ripple-handler"
                        @click.prevent="removeFromCart(product.id)">
                        <span class="ripple-mask"><span class="ripple"></span></span>
                    </button>
                    <span class="counter">{{ productTotal }}</span>
                    <button class="item-incr-button button-item ripple-handler" @click.prevent="addToCart(product.id)">
                        <span class="button-item-label">В корзину</span>
                    </button>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'catalog-item',
    data() {
        return {
            galleryImg: '',
            imgNotFound: false,
            errorImg: require('@/assets/img/loading-error.png'),
            productPrice: 0,
            productOldPrice:0
        }
    },
    props: {
        product: {
            type: Object,
            required: true
        },
        isShowCart: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        productLink(id) {
            return '/catalog/' + id;
        },
        priceFormat(price) {
            return this.$filters.price_format(price);
        },
        addToCart(productId) {
            this.$store.commit('addToCart', productId);
        },
        removeFromCart(productId) {
            this.$store.commit('removeFromCart', productId);
        }
    },
    mounted() {
        if (this.product) {
            if (typeof this.product.pictures !== 'undefined') {
                this.galleryImg = this.product.pictures[0];
            } else {
                this.galleryImg = this.errorImg;
                this.imgNotFound = true;
            }
            this.productPrice = this.product.price ? this.product.price : 0;
            this.productOldPrice = this.product.oldPrice ? this.product.oldPrice : 0;
        }
    },
    computed: {
        productTotal() {
            return this.$store.getters.productQuantity(this.product.id);
        },
        isAvailable() {
            return Boolean(this.product.available);
        }
    }
};
</script>

<style scoped>
.list__item-cart {
    max-width: 100%;
    width: 100%;
    margin: auto auto 0;

}

.item-buttons__wr {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.item-buttons {
    width: 80px;
    display: flex;
    justify-content: space-between;
    margin: 0 0 0 auto;
    position: relative;
    transition: all var(--animation);
    border-radius: 4px;
}

.item-decr-button {
    background-color: transparent;
    pointer-events: none;
    transform: scale3d(.9, .9, 1);
    transition: transform var(--animation), visibility var(--animation);
    visibility: hidden;
    width: 34px;
    height: 34px;
    padding: 0
}

.item-incr-button {
    position: absolute;
    top: 0;
    right: 0;
    transition: width var(--animation);
    width: 100%;
    height: 100%;
    padding: 0;
    background-color: var(--theme-link-color);
    text-transform: none;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}

.item-decr-button:before,
.item-incr-button:after,
.item-incr-button:before {
    display: inline-block;
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 12px;
    height: 2px;
    background-color: transparent;
    border-radius: 1px;
    z-index: 1;
    transition: background-color var(--animation);
}

.item-incr-button:after {
    width: 2px;
    height: 12px
}

.item-incr-button .button-item-label {
    transition: transform var(--animation);
}

.item-incr-button .button-item-label {
    margin-right: 10px;
}

.button-item-label {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
    position: relative;
    z-index: 1;
    font-size: 12px;
    text-align: center;
    width: 100%;
    font-weight: 300;
}

.item-incr-button svg {
    width: 14px;
    height: 18px;
    margin-bottom: -1px;
}

.list__item-cart.selected .item-incr-button:after,
.list__item-cart.selected .item-incr-button:before,
.list__item-cart.selected .item-decr-button:before {
    background-color: #64748B;
}

.list__item-cart.selected .item-buttons {
    background-color: #E2E8F0;
    width: 75px;
    max-width: 75px;
    min-width: 75px;
}

.list__item-cart.selected .item-decr-button {
    pointer-events: auto;
    transform: scaleX(1);
    visibility: visible;
}

.list__item-cart.selected .item-incr-button {
    width: 34px;
    height: 34px;
    top: auto;
    bottom: 0;
    background-color: transparent;
}

.list__item-cart.selected .item-incr-button .button-item-label,
.list__item-cart.selected .item-incr-button svg {
    display: none;
}

.list__item-cart .cart-link {
    width: 32px;
    height: 34px;
    margin-left: 8px;
}

.list__item-cart .counter,
.list__item-cart .cart-link {
    display: none;
}

.list__item-cart.selected .counter {
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 24px;
    height: 34px;
    background-color: transparent;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 34px;
    color: #334155;
    padding: 0;
    text-align: center;
}

.list__item-cart.selected .cart-link {
    display: inline-block;
}

.list__item-price {
    display: flex;
    align-items: center;
}

.list__item-price ins,
.list__item-price del {
    text-decoration: none;
}

.list__item-price ins {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 15px;
    color: var(--theme-button-color);
}

.list__item-price del {
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    font-size: 8px;
    color: var(--theme-hint-color);
    position: relative;
    margin-left: -5px;
    margin-top: -25px;
}

.list__item-price del:after {
    content: "";
    width: 90%;
    height: 1px;
    background-color: var(--theme-hint-color);
    position: absolute;
    top: 7px;
    left: 0;
    right: 0;
    transform: rotate(-9deg);
}
</style>