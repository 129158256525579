<template>
    <catalog-header v-if="!is_wishlist" :total-count-string="totalCountString" :category-name="categoryName" />
    <section class="list">
        <div class="wrapper" v-if="(products.length > 0)">
            <catalog-item v-for="product in products" :product="product" :key="product.id" :data-item-id="product.id" />
        </div>
        <div class="wrapper" v-if="(products.length === 0 && !loading)">
            <p class="lead text-center">{{ empty_text }}</p>
        </div>
        <loading v-if="loading" />
        <div ref="observer" class="observer"></div>
    </section>
</template>
<script>
import { mapGetters } from 'vuex';
import CatalogHeader from '@/components/CatalogHeader.vue';
import CatalogItem from '@/components/CatalogItem.vue';


export default {
    data() {
        return {
            loadMore: false,
            loading: true,
            products: [],
            pageNum: 1,
            totalPages: 0,
            limit: 8,
            offset: 0,
            totalCount: 0,
            totalCountString: ''
        }
    },
    components: {
        CatalogHeader,
        CatalogItem
    },
    props: {
        is_wishlist: {
            type: Boolean,
            required: false,
            default: false
        },
        wishlist_ids: {
            type: String,
            required: false,
            default: ''
        },
        empty_text: {
            type: String,
            required: false,
            default: 'Нет товаров по выбранным параметрам'
        }
    },
    methods: {
        async fetchData() {

            this.loading = true;

            let fltParams = this.$route.query ? this.$route.query : {};
            if (Object.keys(fltParams).length) {
                let fltParamsJSON = '';
                fltParamsJSON = JSON.stringify(fltParams).replaceAll('[]', '');
                fltParams = fltParamsJSON ? JSON.parse(fltParamsJSON) : {};
            } else {
                fltParams = {}
            }

            let productFilters = {
                ...fltParams,
                ...{
                    skip: this.offset,
                    limit: this.limit,
                    regionId: this.cityId
                }
            };


            await this.$http.post('search', {
                params: productFilters,
            }).then((response) => {
                if (response.data.offers) {

                    if (this.pageNum === 1) {
                        this.products = response.data.offers;
                        let queryFlt = (typeof fltParams.query !== 'undefined') ? fltParams.query : '';
                        this.setFilters(queryFlt, response.data.hits, response.data.params);
                    } else {
                        this.products = [...this.products, ...response.data.offers];
                    }

                    this.totalCount = response.data.hits;
                    this.totalCountString = this.$filters.number_format(this.totalCount) + ' ' + this.$filters.num_word(this.totalCount, ['товар', 'товара', 'товаров']);

                    if (response.data.offers.length < this.limit) {
                        this.loadMore = false;
                    } else {
                        this.loadMore = true;
                    }

                    this.offset = this.pageNum * this.limit;
                    this.pageNum++;

                    this.loading = false;

                } else {
                    this.loading = false;
                    this.loadMore = false;
                }
            }).catch((error) => {
                console.log(error);
            });

        },
        loadMoreData(type = '') {
            this.fetchData();
        },
        setFilters(query, hits, params) {
            let cnt = {
                id: "hits",
                value: hits
            };
            let queryParam = {
                id: "query",
                value: query
            };
            params = [...params, cnt, queryParam];
            this.$store.commit('updateStateParam', { title: 'incoming_filters', value: params });
        }
    },
    mounted() {
        //===loading by scroll START===
        const scrollOptions = {
            rootMargin: '0px',
            threshold: 1
        }
        const scrollCallback = (enteries, observer) => {
            if (enteries[0].isIntersecting && this.loadMore) {
                this.loading = true;
                this.loadMoreData('scroll');
            }
        }
        const observer = new IntersectionObserver(scrollCallback, scrollOptions);
        observer.observe(this.$refs.observer);
        //===loading by scroll END=====
    },
    computed: {
        ...mapGetters([
            'GET_CURRENT_RAZDEL_NAME',
        ]),
        categoryName: function () {
            let resId = (typeof this.$route.query.categories !== 'undefined') ? this.$route.query.categories : 0;
            let resName = (resId > 0) ? this.GET_CURRENT_RAZDEL_NAME(resId) : '';
            if (resName.length === 0 && typeof this.$route.query.query !== 'undefined') {
                resName = 'Скидки и акции';
            }
            return (resName.length > 0) ? resName : 'Каталог';
        },
    },
    watch: {
        "$route.query": {
            handler: function (value, old) {
                this.products = [];
                this.pageNum = 1;
                this.offset = 0;
                this.loadMoreData('flt');
            },
            deep: true,
            immediate: true,
        },
    }
}
</script>

<style scoped>
.observer {
    height: 40px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
}

.list {
    margin-bottom: 80px;
    position: relative;
    min-height: 100vh;
}

.list>.wrapper {
    padding-top: 0;
    display: flex;
    flex-flow: row wrap;
    gap: 45px 20px;
}

.list .list__item {
    max-width: 100%;
    width: calc((100% - 20px) / 2);
}
</style>