<template>
    <section v-if="(products.length > 0)" class="prod-carousel">
        <div class="wrapper">
            <div class="section-header">
                <h2>{{ title }}</h2>
                <router-link :to="catalogLink">
                    <span>Все</span>
                    <svg class="angle-right-icon" width="7" height="12" viewBox="0 0 7 12">
                        <use href="#angle-right" xlink:href="#angle-right"></use>
                    </svg>
                </router-link>
            </div>
            <div class="swiper prodCarousel">
                <div class="swiper-wrapper list" id="prod-carousel">
                    <catalog-item v-for="product in products" :product="product" :key="product.id"
                        :data-item-id="product.id" :is-show-cart="false"/>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Swiper from '@/assets/lib/swiper/swiper-bundle.min.js';
import CatalogItem from '@/components/CatalogItem.vue';

export default {
    components: {
        CatalogItem,
    },
    props: {
        title: {
            type: String,
            default: 'Популярные товары'
        },
        products: {
            type: Array,
            default: [],
            required: true
        },
        fltParams: {
            type: Object,
            default: {}
        }
    },
    computed: {
        catalogLink() {
            let $paramsStr = Object.keys(this.fltParams).map(key => `${key}=${encodeURIComponent(this.fltParams[key])}`).join('&');
            return '/catalog' + ($paramsStr ? '/?' + $paramsStr : '');
        },
    },
    updated() {
        this.$nextTick(() => {
            if ($('.prodCarousel').length) {
                new Swiper(".prodCarousel", {
                    slidesPerView: "auto",
                    spaceBetween: 15
                });
            }
        });
    }

}
</script>


<style scoped>
@import '@/assets/lib/swiper/swiper-bundle.min.css';

.prod-carousel h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-top: 0;
    margin-bottom: 10px;
    color: var(--theme-text-color);
}

.prodCarousel.swiper {
    padding-bottom: 0px !important;
}

.prodCarousel.swiper-horizontal>.swiper-pagination-bullets,
.prodCarousel .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0 !important;
}
</style>